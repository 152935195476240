



































































































































































































$cor: #0091ff;
.relatorio-dre-init{
  .none{
    display: none;
  }
  .conteudo-div{
    padding-top:0 ;
    > table{
      background: white !important;
    }
    @media(max-width: 1320px) {
      table{
        width: 1290px;
      }
      overflow-x: scroll;
    }
  }
  .relatorio {
    padding-top: 0;
    padding-bottom: 0;
    position: relative;
    width: 100%;
    display: flex;
    align-items: 'center';
    justify-content: 'center';
    &-download {
      width: 100%;
      &-icons {
        text-align: right;
        width: 40px;
        color: $cor ;
        background:#fafafa;
        padding: 5px;
        display: inline-block;
        border-radius: 5px;
        margin-left: 5px;
        border: 1px solid $cor;
        font-size: 0.8rem;
        font-weight: 600;
        cursor: pointer;
        &:hover{
          background:$cor ;
          opacity: 0.8;
          color: white;
        }
        &:first-child{
          margin-right: 5px;
        }
      }
    }
    &-pagination{
      position: relative;
      display: flex;
      justify-content: flex-end;
      @media(max-width: 660px) {
        justify-content: center;
      }
      align-items: center;
      flex-wrap: nowrap;
      width: 100%;
      span {
        display: flex;
        &:last-child {
          cursor: pointer;
        }
        &:nth-child(2) {
          justify-content: center;
          align-items: center;
        }
        &:first-child {
          cursor: pointer;
          justify-content: flex-end;
        }
      }
    }

  }
  .default-colunas {
    margin-bottom: 0;
    padding-bottom: 0;
    padding-top: 0;
  }
  .center-itens{
    &-download {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      // @extend .default-colunas;
    }
    &-datas {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      // @extend .default-colunas;
    }
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    @extend .default-colunas;
    > div {
      padding: 0;
    }
  }
  .v-list {
    position: absolute;
    border: 1px solid $cor;
    background: #fafafa;
    z-index: 1;
    width: 72px;
    @media(max-width: 769px){
      right: 25px;
    }
    .v-list-item{
      min-height: 30px;
      &__title{
        font-size: 12px;
        font-weight: 600;
      }
      &:hover  {
        color: $cor !important;
      }
    }
  }
  .bottom{
    margin-right: 5px;
    box-shadow: none !important;
    &-2{
      background-color: transparent !important;
      border: 1px solid $cor;
      color: $cor !important;
      box-shadow: none !important;
    }
    &-mobile {
      @media(max-width: 769px) {
      width: 100%;
      text-align: right;
      }
    }
  }
}
